'use client'

import Script from 'next/script'

const AtlasSoScript = ({
	appId,
	userObject,
}: {
	appId: string
	userObject: {
		userId: string
		name: string
		email: string
		userHash: string
	}
}) => {
	return (
		<>
			<Script id="atlas-snippet">
				{`(()=>{"use strict";var t,e={appId:"${appId}",v:2,q:[],call:function(){this.q.push(arguments)}};window.Atlas=e;var n=document.createElement("script");n.async=!0,n.src="https://app.atlas.so/client-js/atlas.bundle.js";var s=document.getElementsByTagName("script")[0];null===(t=s.parentNode)||void 0===t||t.insertBefore(n,s)})();`}
			</Script>
			<Script id="atlas-start">
				{`
					window.Atlas.call(
						"start",
						{
							chat: {
								hideBubble: true,
								brandColor: '#FFA31A',
								textColor: 'dark', 
								launcher: {
								  label: 'Help', 
								},
								position: 'topRight',
								offset: [20, 60]
							}
						}
					);
				`}
			</Script>
			<Script id="atlas-identify-call">
				{`
				  window.Atlas.call(
					"identify",
					${JSON.stringify(userObject)}
				  )
				`}
			</Script>
		</>
	)
}
export default AtlasSoScript
